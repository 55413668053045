import {
  deleteJob,
  upscale,
  extend,
  generateAfc,
  repaint,
  inpaint,
  generateBB8,
  generateImg,
  gen2Image2Video,
  gen2Text2Image,
  gen2Text2Video,
  postTemplateJob,
} from '@/service/job.service'
import {
  Creation,
  PoNVoid,
  GalleryItemSource,
  ExtendGenerationParams,
  UpscaleGenerationParams,
  CreationModeEnum,
  CreationOutput,
  CreationBase,
} from '@/types'
import {
  addQueryParams,
  getNextExtendDuration,
  getVideoDuration,
  isCreation,
  utcDate,
  utcDateTime,
  whisper,
} from '@/utils'
import { nanoid } from 'nanoid'
import { ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import useDownloadFile from '@/hooks/useDownloadFile'
import useCreation from '@/hooks/useCreation'
import { toast } from '@/components/ui/use-toast'
import { useSetAtom } from 'jotai'
import {
  activeTemplateIdAtom,
  creationCacheAtom,
  creationInputAtom,
  deletedCreationIdsAtom,
  jobsRefreshKeyAtom,
  loginDialogOpenAtom,
  unFavouriteGenerationIdsAtom,
} from '@/atoms'
import { postCollectStateToCreationById, postWatermarkFreeUrl } from '@/service/creation.service'
import { useCachedMyProfile } from '@/hooks/useMyProfile'
import useAuth0Auth from '@/hooks/useAuth0Auth'
import useActivePlan from '@/hooks/useActivePlan'
import useAmplitude from '@/hooks/useAmplitude'
import useCredit from '@/hooks/useCredit'
import { cloneDeep, isNil, pick } from 'lodash-es'
import { useCachedVideoDuration } from '@/hooks/useVideoDuration'
import { saveToCollection } from '@/service/collection.service'
import { useRouter } from 'next/navigation'
import { useCachedOutput } from './useOutput'
import { useCachedCollections } from './useCollections'
import { CreditSpendButtonProps } from '@/components/credit-spend-button'

export interface UseCreationActionsParams {
  source: GalleryItemSource
  creationId: string
  outputId: string | undefined
  initialData?: CreationBase
  inDialog?: boolean
  onDelete?: (creationId?: string) => PoNVoid
  onCloseModal?: () => void
  url?: string
}

export interface UseCreationActionsResult {
  handleDownload: (watermarkFree: boolean) => Promise<boolean>
  handleDelete: () => PoNVoid
  handleFavorite: () => PoNVoid
  handleExtend: (newPrompt: string) => PoNVoid
  handleUpscale: () => PoNVoid
  setIsPublic: (isPublic: boolean) => PoNVoid
  handleImageDownload: () => PoNVoid
  handleRegenerate: () => PoNVoid
  handleVaryPrompt: (newPrompt: string) => PoNVoid
  handleAddCollection: (keys: string[]) => PoNVoid
  handleImage2Video: () => PoNVoid
  handleOpenComment: () => PoNVoid
  handleRepaint: () => PoNVoid
  refreshJobs: () => PoNVoid

  extendDisableMessage?: { title: string; content: ReactElement | string }
  upscaleDisableMessage?: { title: string; content: ReactElement | string }
  repaintDisableMessage?: { title: string; content: ReactElement | string }
  favorited: boolean
  hasAddCollection: boolean
  collectsCount: number
  shareLink?: string
  isPublic: boolean
  isAuthor: boolean
  creation?: Creation
  creationId: string
  outputId?: string
  base?: CreationBase | null
  output?: CreationOutput
  trackEventParams: Record<string, any>
  nextExtendDuration: number | null
  realDuration: number
}

export function formatSpu(creation: Creation | undefined): CreditSpendButtonProps['spu'] {
  if (!creation) {
    return 'generation/txt2vid'
  }

  function shortIOType(x: string) {
    if (x === 'text') {
      return 'txt'
    } else if (x === 'image') {
      return 'img'
    } else if (x === 'video') {
      return 'vid'
    } else {
      return x
    }
  }

  const { type, input_type, output_type = 'video' } = creation

  const inputType = shortIOType(input_type)
  const outputType = shortIOType(output_type)
  const spuType = `${type}/${inputType}2${outputType}`
  return spuType as any
}

export const regenerate = async (creation: Creation, params?: Pick<Creation, 'prompt' | 'is_public'>) => {
  const spu = formatSpu(creation)
  const newParmas = pick(creation, ['prompt', 'is_public', 'parent_id', 'config', 'settings'])
  if (!isNil(params?.prompt)) {
    newParmas.prompt = params?.prompt ?? ''
  }

  if (!isNil(params?.is_public)) {
    newParmas.is_public = params?.is_public
  }

  if (newParmas?.settings?.seed) {
    delete newParmas.settings.seed
  }

  const spu2Api = {
    'generation/txt2vid': generateBB8,
    'gen2/txt2vid': gen2Text2Video,
    'generation/txt2img': generateImg,
    'gen2/txt2img': gen2Text2Image,
    'generation/img2vid': generateBB8,
    'gen2/img2vid': gen2Image2Video,
    'repainting/vid2vid': repaint,
    'inpainting/vid2vid': inpaint,
    'extend/vid2vid': extend,
    'upscale/vid2vid': upscale,
    'afc/img2vid': generateAfc,
    'template/any2vid': postTemplateJob,
  }

  const api = spu2Api[spu]

  const res = await api(newParmas as any)
  return res
}

export default function useCreationActions({
  creationId,
  outputId,
  initialData,
  source,
  url,
  onDelete,
  onCloseModal,
  inDialog,
}: UseCreationActionsParams): UseCreationActionsResult {
  const { track } = useAmplitude()

  const needCreation = useMemo(() => {
    const sourcesRequireCreation: GalleryItemSource[] = ['creations', 'collection', 'video-detail', 'image-detail']
    return sourcesRequireCreation.includes(source)
  }, [source])

  const needOutput = !needCreation || source === 'image-detail' || source === 'video-detail'

  const { isLogin } = useAuth0Auth()
  const setCreationCache = useSetAtom(creationCacheAtom)
  const setLoginDialogOpen = useSetAtom(loginDialogOpenAtom)
  const { refresh: refreshCollections } = useCachedCollections()

  const { data: activePlan } = useActivePlan()
  const canGeneratePrivateVideo = !!activePlan?.allow_private_generation

  const showAuthDialog = useCallback(() => {
    setLoginDialogOpen(true)
  }, [setLoginDialogOpen])

  const { data: profile } = useCachedMyProfile()
  const setUnfavouriteGenerationIds = useSetAtom(unFavouriteGenerationIdsAtom)

  const initialDataIsCreation = initialData && isCreation(initialData)
  const initialCreation = initialDataIsCreation ? (initialData as Creation) : null
  const initialCreationOutput = initialDataIsCreation ? null : (initialData as CreationOutput)

  // job item
  const { data: creationRes } = useCreation(!!initialCreation || !needCreation ? '' : creationId)
  const creation = creationRes?.data ?? initialCreation ?? null

  const { data: outputRes } = useCachedOutput(needOutput && !initialCreationOutput ? outputId : '')
  const output = outputRes?.data ?? initialCreationOutput ?? null

  const base: CreationBase | null = output ?? creation ?? initialData ?? null

  const { refreshCredit } = useCredit()

  const isPublic = base?.is_public ?? true

  const setIsPublic = useCallback(
    (value: boolean) => {
      if (creation) {
        setCreationCache((old) => {
          return {
            ...old,
            [creation?.creation_id]: {
              ...creation,
              is_public: value,
            },
          }
        })
      }
    },
    [setCreationCache, creation],
  )

  const [innerFavorited, setInnerFavorited] = useState<boolean | null>(null)
  const [innerHasAddCollection, setInnerHasAddCollection] = useState<boolean | null>(null)
  const [innerCollectsCount, setInnerCollectsCount] = useState<number>(0)

  const filename = useMemo(() => {
    return `${creationId || 'video'}.mp4`
  }, [creationId])

  const { download } = useDownloadFile({
    url,
    name: filename,
  })

  const hasWatermarkFreeUrl = !!base?.is_watermark_free_enabled

  const isAuthor = profile?.user_id === base?.user_id

  const trackEventParams = useMemo(() => {
    const createTime = creation?.create_time ?? output?.output_create_time ?? null
    const updateTime = creation?.update_time ?? output?.output_update_time ?? null

    return {
      creation_id: creationId,
      source,
      is_author: isAuthor,
      create_time: createTime ? utcDateTime(createTime) : null,
      create_date: updateTime ? utcDate(updateTime) : null,
    }
  }, [source, isAuthor, creationId, creation, output])

  const collected = output?.commits?.is_collect ?? false
  const collectsCount = output?.commits?.collects_count ?? 0

  const hasAddCollection = creation?.is_collected ?? false

  const setCreationInput = useSetAtom(creationInputAtom)

  const router = useRouter()

  const gotoCreations = useCallback(() => {
    if (inDialog) {
      router.back()
      onCloseModal?.()
    }
    setTimeout(() => {
      router.push('/creations')
    }, 0)
  }, [router, inDialog, onCloseModal])

  const setJobsRefreshKey = useSetAtom(jobsRefreshKeyAtom)

  const refreshJobs = useCallback(() => {
    setJobsRefreshKey(nanoid())
    gotoCreations()
  }, [setJobsRefreshKey, gotoCreations])

  const handleImage2Video = useCallback(() => {
    if (creation && output) {
      const url = output.output_url
      whisper('url is: ', url)
      setCreationInput((prev) => ({
        ...prev,
        mode: CreationModeEnum.AnimateHD,
        creation: {
          ...creation,
          settings: { ...creation.settings, resolution: 720 },
        },
        expanded: true,
        focusing: true,
        img: url,
      }))
      gotoCreations()
    }
  }, [creation, setCreationInput, gotoCreations, output])

  const setActiveTemplateId = useSetAtom(activeTemplateIdAtom)
  const handleRepaint = useCallback(() => {
    if (!creation) {
      return
    }
    track('click:creation:repaint', {
      creation_id: creationId,
    })
    setCreationInput((prev) => ({
      ...prev,
      mode: CreationModeEnum.Repaint,
      creation,
      expanded: true,
      focusing: true,
    }))
    setActiveTemplateId(null)
    gotoCreations()
  }, [creationId, creation, setCreationInput, track, gotoCreations, setActiveTemplateId])

  // settingsDuration is not accurate, use videoDuration instead. For example, inpainting jobs passes 4 as duration, but the real duration is 2, and returned settings.duration is 8.
  const settingsDuration =
    creation?.type === 'generation' || creation?.type === 'gen2' ? creation?.settings?.duration ?? 0 : 0

  // const { data: videoDuration, isValidating: videoDurationLoading } = useCachedVideoDuration(settingsDuration ? '' : creation?.video_url)
  const videoUrlWithStatus = useMemo(() => {
    if (!creation?.video_url) {
      return ''
    }
    return addQueryParams(creation?.video_url, {
      status: creation?.status,
    })
  }, [creation?.video_url, creation?.status])

  // we only need video duration for current user's video, and only when settingsDuration is not available
  // videoDuration is used for extend duration calculation and credit deduction
  const shouldFetchDurationFromVideoUrl = !settingsDuration && isAuthor && source === 'creations'

  const { data: videoDuration, loading: videoDurationLoading } = useCachedVideoDuration(
    shouldFetchDurationFromVideoUrl ? videoUrlWithStatus : '',
  )

  const realDuration = Math.floor(videoDuration || settingsDuration || 0)

  const nextExtendDuration = useMemo(() => {
    if (creation?.type === 'extend') {
      return 0
    }
    const defaultExtendDuration = 4
    if (!realDuration || videoDurationLoading) {
      return defaultExtendDuration
    }
    return getNextExtendDuration(realDuration)
  }, [realDuration, videoDurationLoading, creation])

  useEffect(() => {
    setInnerFavorited(collected)
  }, [collected])

  useEffect(() => {
    setInnerHasAddCollection(hasAddCollection)
  }, [hasAddCollection])

  useEffect(() => {
    setInnerCollectsCount(collectsCount)
  }, [collectsCount])

  const statesRef = useRef({
    collected: innerFavorited,
  })

  useEffect(() => {
    statesRef.current = {
      collected: innerFavorited,
    }
  }, [innerFavorited])

  const handleImageDownload = useCallback(async () => {
    track('click:creation:image:download', {
      image_id: output?.output_id,
      image_url: output?.output_url,
    })
    const response = await fetch(output?.output_url || '')
    const blob = await response.blob()
    const urlBlob = URL.createObjectURL(blob)

    const a = document.createElement('a')
    a.href = urlBlob
    a.download = 'image.png'
    document.body.appendChild(a)
    a.click()
    URL.revokeObjectURL(urlBlob)
    document.body.removeChild(a)
  }, [output, track])

  const handleDownload = useCallback(
    async (watermarkFree = false) => {
      if (!isLogin) {
        showAuthDialog()
        return false
      }

      track('click:creation:download', {
        ...trackEventParams,
        watermark_free: watermarkFree,
        has_watermark_free_url: hasWatermarkFreeUrl,
      })

      if (watermarkFree) {
        const url = (await postWatermarkFreeUrl(creationId))?.url
        await download(url)
      } else {
        await download()
      }
      return true
    },
    [download, isLogin, showAuthDialog, creationId, track, trackEventParams, hasWatermarkFreeUrl],
  )

  const setDeletedCreationIds = useSetAtom(deletedCreationIdsAtom)
  const handleDelete = useCallback(async () => {
    if (!isLogin) {
      showAuthDialog()
      return
    }
    await deleteJob(creationId)
    setDeletedCreationIds((prev) => {
      return [...prev, creationId]
    })
    toast({
      title: 'Video has been deleted',
      color: 'success',
    })
    onDelete?.(creationId)
  }, [isLogin, creationId, showAuthDialog, onDelete, setDeletedCreationIds])

  const handleAddCollection = useCallback(
    async (keys: string[]) => {
      track('click:creation:addToCollection', {
        creationId,
        collectionIds: keys,
      })
      setInnerHasAddCollection(keys.length > 0)
      try {
        await saveToCollection({
          collection_ids: keys,
          creation_id: creationId ?? '',
        })
        if (creation && creationId) {
          setCreationCache((prev) => ({
            ...prev,
            [creationId]: {
              ...creation,
              is_collected: keys.length > 0,
            },
          }))
        }
        refreshCollections()
      } catch (error) {
        setInnerHasAddCollection(!!creation?.is_collected)
      }
    },
    [track, creation, refreshCollections, setCreationCache, creationId],
  )

  const handleFavorite = useCallback(async () => {
    if (!isLogin) {
      showAuthDialog()
      return
    }
    const newState = !statesRef.current.collected
    if (newState) {
      track('click:creation:favorite', trackEventParams)
    }
    setInnerFavorited(newState)
    setInnerCollectsCount((old) => {
      return Math.max(0, old + (newState ? 1 : -1))
    })

    setUnfavouriteGenerationIds((old) => {
      return newState ? old.filter((id) => id !== creationId) : [...old, creationId]
    })
    try {
      await postCollectStateToCreationById(
        {
          output_id: output?.output_id ?? creationId,
          is_collect: newState,
        },
        'v2',
      )
    } catch (error) {
      setInnerFavorited(!newState)
    }
  }, [output, creationId, setUnfavouriteGenerationIds, isLogin, showAuthDialog, track, trackEventParams])

  const originalVideoId = useMemo(() => {
    return creation?.output_video ?? ''
  }, [creation])

  const handleExtend = useCallback(
    async (newPrompt: string) => {
      track('click:creation:extend', trackEventParams)
      const params: ExtendGenerationParams = cloneDeep({
        prompt: newPrompt ?? '',
        is_public: isPublic || !canGeneratePrivateVideo,
        parent_id: creationId ?? '',
        config: {
          extend_duration: nextExtendDuration,
          source_video: originalVideoId,
        },
      })
      if (refreshCredit) {
        await refreshCredit()
      }
      await extend(params)
      refreshJobs()
      gotoCreations()
    },
    [
      isPublic,
      canGeneratePrivateVideo,
      track,
      creationId,
      originalVideoId,
      nextExtendDuration,
      trackEventParams,
      refreshCredit,
      refreshJobs,
      gotoCreations,
    ],
  )

  const handleUpscale = useCallback(async () => {
    track('click:creation:upscale', trackEventParams)
    const videoDuration = Math.round(await getVideoDuration(creation?.video_url ?? '')) || 0

    const params: UpscaleGenerationParams = cloneDeep({
      prompt: creation?.prompt ?? '',
      is_public: isPublic || !canGeneratePrivateVideo,
      parent_id: creationId ?? '',
      config: {
        source_video: originalVideoId,
      },
      settings: {
        duration: videoDuration,
      },
    })
    await upscale(params)
    if (refreshCredit) {
      await refreshCredit()
    }
    refreshJobs()
    gotoCreations()
  }, [
    isPublic,
    originalVideoId,
    canGeneratePrivateVideo,
    track,
    trackEventParams,
    refreshCredit,
    creation,
    creationId,
    refreshJobs,
    gotoCreations,
  ])

  const extendDisableMessage = useMemo(() => {
    if (videoDurationLoading) {
      return {
        title: '',
        content: 'Loading',
      }
    }
    if (creation?.type === 'extend') {
      return {
        title: '',
        content: 'You already extended this video',
      }
    }
    if (creation?.type === 'upscale') {
      return {
        title: '',
        content: 'Unavailable for enhanced video',
      }
    }
  }, [creation?.type, videoDurationLoading])

  const upscaleDisableMessage = useMemo(() => {
    if (videoDurationLoading) {
      return {
        title: '',
        content: 'Loading',
      }
    }
    if (creation?.type === 'upscale') {
      return {
        title: '',
        content: 'You already enhanced this video',
      }
    }
  }, [creation?.type, videoDurationLoading])

  const repaintDisableMessage = useMemo(() => {
    if (creation?.type === 'extend') {
      return {
        title: '',
        content: 'Unavailable for extended video',
      }
    }
    if (creation?.type === 'upscale') {
      return {
        title: '',
        content: 'Unavailable for enhanced video',
      }
    }
  }, [creation?.type])

  const handleOpenComment = useCallback(() => {
    let url = `/creation/${creationId}`
    // if (initialData?.output_id !== generationId) {
    //   url += `?output_id=${initialData?.output_id}`
    // }
    if (initialData?.output_type === 'image') {
      // url += '?type=output'
      url = `/creation/${outputId}?type=output`
    }
    router.push(url)
  }, [creationId, initialData, router, outputId])

  const handleRegenerate = useCallback(async () => {
    if (!creation) {
      return
    }
    await regenerate(creation, {
      is_public: isPublic || !canGeneratePrivateVideo,
    })
  }, [creation, canGeneratePrivateVideo, isPublic])

  const handleVaryPrompt = useCallback(
    async (newPrompt: string) => {
      if (!creation) {
        return
      }
      const res = await regenerate(creation, {
        prompt: newPrompt,
        is_public: isPublic || !canGeneratePrivateVideo,
      })
      return res
    },
    [creation, canGeneratePrivateVideo, isPublic],
  )

  return {
    handleDownload,
    handleDelete,
    handleFavorite,
    handleAddCollection,
    handleImage2Video,
    handleExtend,
    handleRepaint,
    handleOpenComment,
    handleUpscale,
    handleImageDownload,
    handleRegenerate,
    handleVaryPrompt,
    refreshJobs,
    isPublic,
    isAuthor,
    creationId,
    outputId,
    base,
    setIsPublic,
    favorited: innerFavorited ?? false,
    hasAddCollection: innerHasAddCollection ?? false,
    collectsCount: innerCollectsCount ?? 0,
    shareLink: `${location.origin}/creation/${creationId}`,
    creation: creation ?? undefined,
    output: output ?? undefined,
    extendDisableMessage,
    upscaleDisableMessage,
    repaintDisableMessage,
    realDuration,
    nextExtendDuration,
    trackEventParams,
  }
}
